
import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';

import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import DayChanger from '@/modules/common/components/day-changer.vue';
import MarketsDayScanBtn from '@/modules/markets/components/markets-day-scan-btn.vue';
import Day from '@/modules/common/types/day.type';
import formatDate from '@/modules/common/filters/format-date.filter';
import MarketsCompsetMainModel from '@/modules/cluster/models/markets-compset-main.model';
import MarketsDocumentModel from '@/modules/markets/models/markets-document.model';

import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import MarketsCommonService, { MarketsCommonServiceS } from '../../markets/markets-common.service';

@Component({
    components: {
        DayChanger,
        DateDocumentFilter,
        MarketsDayScanBtn,
    },
})
export default class MarketsDemandMapHeader extends Vue {
    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(MarketsCommonServiceS)
    private marketsCommonService!: MarketsCommonService;

    @Inject(ClusterServiceS)
    private clusterService!: ClusterService;

    @Inject(MarketsServiceS)
    private marketsService!: MarketsService;

    get scanProps() {
        const compsetId = this.$route.params.compsetId || this.documentFiltersService.compsetId;
        const { los, pos } = this.documentFiltersService.settings;
        const { day, hotelId, provider } = this;

        return {
            day,
            settings: {
                compsetId,
                provider,
                los,
                pos,
                hotelId,
            },
        };
    }

    get provider() {
        return this.$route.params.source;
    }

    get hotelId() {
        return +this.$route.params.hotelId;
    }

    get day() {
        return +this.$route.params.day as Day;
    }

    get isCluster() {
        return this.$route.name!.startsWith('cluster.')
            || this.$route.name!.startsWith('chain.');
    }

    get document() {
        if (this.isCluster) {
            return this.clusterService
                .getMainCompsetData<MarketsCompsetMainModel>(this.hotelId) as unknown as MarketsDocumentModel;
        }

        return this.marketsService.getDocument(this.$route.params.source);
    }

    get formatScanDate() {
        if (!this.document) {
            return '--/--/--';
        }

        const dateScan = this.marketsCommonService
            .dayUpdateDate(this.day, this.document);

        return formatDate(dateScan ? new Date(dateScan) : null);
    }
}
