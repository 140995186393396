var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.skeleton)?_c('div',{staticClass:"statistics"},[_c('MarketName',{attrs:{"marketId":_vm.marketId}}),(!_vm.isNoData && !_vm.isNoVisibility && !_vm.skeleton)?_c('div',{class:{
            'metric': true,
            ..._vm.dayColor,
        },attrs:{"title":_vm.$tc('titles.rank')},domProps:{"textContent":_vm._s([_vm.myHotelPosition, _vm.validHotels.length].join('/'))}}):_vm._e(),(_vm.isNoData || _vm.isNoVisibility)?_c('div',{class:{
            'metric': true,
            'no-data': _vm.isNoData,
            'is-na': _vm.isNoVisibility,
        },domProps:{"textContent":_vm._s(_vm.$tc('markets.noVisibility'))}}):_vm._e(),(_vm.hotelsOnMarket)?_c('div',{staticClass:"metric",attrs:{"title":_vm.$tc('titles.hotels')},domProps:{"textContent":_vm._s(_vm.hotelsOnMarket)}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }