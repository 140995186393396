
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import MarketService, { MarketServiceS } from '../market.service';

@Component
export default class MarketName extends Vue {
    @Inject(MarketServiceS) private marketService!: MarketService;

    @Prop({
        type: Number,
        required: true,
    })
    marketId!: number;

    get marketName() {
        if (!this.marketId) {
            return '';
        }
        return this.marketService.marketNameById(this.marketId) || '';
    }
}
