
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import MarketsHistoryService, { MarketsHistoryServiceS } from '../markets-history.service';

@Component
export default class RatesPriceHistoryFilters extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(MarketsHistoryServiceS) private marketsHistoryService!: MarketsHistoryService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(UserServiceS) private userService!: UserService;

    get compsetId() {
        return this.$route.params.compsetId
            || this.documentFiltersService.settings.compsetId;
    }

    get compSet() {
        if (!this.compsetsService.compsets) {
            return '';
        }

        const currentCompset = this.compsetsService.getCompset(this.compsetId);
        const type = this.$tc(`compset.${currentCompset?.type}`);

        return currentCompset
            ? `${currentCompset.name} (${type})`
            : '';
    }

    get source() {
        if (this.userService.isViewAsCluster || this.userService.isViewAsChain) {
            return this.marketsHistoryService.marketsDocument?.settings.provider;
        }

        return this.$route.params.source;
    }

    get los() {
        if (this.userService.isViewAsCluster || this.userService.isViewAsChain) {
            return this.marketsHistoryService.marketsDocument?.settings.los;
        }

        return this.documentFiltersService.settings.los || 1;
    }

    get pos() {
        if (this.userService.isViewAsCluster || this.userService.isViewAsChain) {
            return this.marketsHistoryService.marketsDocument?.settings.pos;
        }

        return this.documentFiltersService.settings.pos || '';
    }

    getProviderLabel(providerName: string) {
        const { data } = this.providersService;

        if (!data) {
            return providerName;
        }

        const provider = data.find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }
}
